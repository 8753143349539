import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageTemplateModel } from 'src/app/models/templates/message-template.model';
import { CustomMessageTemplateForGuestModel } from 'src/app/models/templates/custom-message-template-for-guest.model';
import { BookingDetailsService } from 'src/app/routes/booking/settings-booking-detail/booking-details.service';
import { AuthTokenService } from '../../services/auth-token.service';
import { first } from 'rxjs/operators';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Component({
    selector: 'app-message-template-recepient-preview',
    templateUrl: './message-templates-recepient-preview.component.html',
    styleUrls: ['./message-templates-recepient-preview.component.less'],
})
export class MessageTemplateRecepientPreviewComponent implements OnInit {
    @Input() messagePreview: boolean;
    @Output() messagePreviewChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() update: EventEmitter<any> = new EventEmitter<any>();
    @Input() email: string;
    @Input() bookingRoomId: number;
    @Input() bookingGuestId: number;
    @Input() bookingId: number;
    @Input() messageTemplateId: number;
    @Input() mobilePhone: string;
    @Input() invoiceId: string;

    messageTemplate: MessageTemplateModel = null;
    loader = false;
    showTinyEditor = false;
    showRegularEditor = false;
    submittedForm = false;
    theme: number;
    recipient: string;

    hasInvoice = false;
    invoice: string;

    requestModel: CustomMessageTemplateForGuestModel = new CustomMessageTemplateForGuestModel();

    constructor(private bookingDetailsService: BookingDetailsService, private toasterService: ToastService) {
        this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
    }

    ngOnInit() {
        this.bookingDetailsService
            .getGuestMessageTemplateDetails(this.bookingId, this.bookingGuestId, this.messageTemplateId)
            .pipe(first())
            .subscribe(
                (response) => {
                    this.messageTemplate = response;
                    if (this.messageTemplate.type === 'Email') {
                        this.showTinyEditor = true;
                        this.recipient = this.email;
                    } else {
                        this.showRegularEditor = true;
                        this.recipient = this.mobilePhone;
                    }

                    if (this.messageTemplate.invoices.length > 0) {
                        this.hasInvoice = true;
                        if (this.invoiceId != null) {
                            this.invoice = this.invoiceId
                        }
                        
                    }
                    console.log('Mesage template', this.messageTemplate);
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    setValueFromEditor(value) {
        this.messageTemplate.message = value;
    }

    sendMessage() {
        if (this.recipient === null && this.recipient.length === 0) {
            this.toasterService.showToast('Error', 'Please enter a recipient.', null, ToastEventType.Error, true, 2000);
            return;
        }

        this.submittedForm = true;

        if (this.messageTemplate.message) {
            this.loader = true;

            this.requestModel = {
                bookingId: this.bookingId,
                bookingRoomId: this.bookingRoomId,
                recipient: this.recipient,
                messageTemplateId: this.messageTemplate.id,
                message: this.messageTemplate.message,
                invoice: this.invoice,
            };

            this.bookingDetailsService.manuallySendMessageToRecepient(this.requestModel).subscribe(
                (response) => {
                    this.loader = false;
                    this.update.emit();
                    this.close();
                },
                (error) => {
                    this.loader = false;
                    console.log(error);
                }
            );
        }
    }

    close() {
        this.messagePreviewChange.emit(false);
    }

    invoiceSelect(event) {
        if (event.value !== null) {
            this.invoice = event.value;
        } else {
            this.invoice = null;
        }
    }
}
