<div class="partners-wrapper flex">
    <div class="overlay flex">
        <div class="custom-box popup" [ngClass]="[theme == 2 ? 'dark-theme' : '']">
            <h2>Message Preview</h2>

            <div *ngIf="!messageTemplate" class="loader left custom-loader"></div>

            <ng-container *ngIf="messageTemplate">
                <div class="quoter-box">
                    <ul>
                        <li>
                            Title:
                            <span class="blue-letters">
                                <b>{{ messageTemplate.name }}</b></span
                            >
                        </li>
                    </ul>
                    <ul *ngIf="messageTemplate.type == 'Email'">
                        <li>
                            Subject:
                            <span class="blue-letters">
                                <b>{{ messageTemplate.subject }}</b></span
                            >
                        </li>
                    </ul>
                </div>

                <div class="form-item flex">
                    <div class="custom-label">Recipient:</div>
                    <input
                    class="custom-input"
                    name="recipient"
                    type="text"
                    maxlength="150"
                    [(ngModel)]="recipient"
                />
 
                </div>

                <div class="form-item editor mt-20">
                    <ng-container *ngIf="showTinyEditor">
                        <app-tiny-editor
                            [elementId]="'message-template'"
                            [text]="messageTemplate.message"
                            (onEditorContentChange)="setValueFromEditor($event)"
                        ></app-tiny-editor>
                        <span class="text-danger" *ngIf="!messageTemplate.message && submittedForm"
                            >Cannot send blank message.</span
                        >
                    </ng-container>
                    <ng-container *ngIf="showRegularEditor">
                        <textarea name="desc" class="custom-textarea" [(ngModel)]="messageTemplate.message"></textarea>
                        <span class="text-danger" *ngIf="!messageTemplate.message && submittedForm"
                            >Cannot send blank message.</span
                        >
                    </ng-container>
                </div>


                <div class="form-item flex invoice-attachment" *ngIf="hasInvoice">
                    <div class="custom-label">Invoice Attachment:</div>
                    
                    <dx-select-box
                        showClearButton="true"
                        class="custom-select"
                        placeholder="Select invoice..."
                        [dataSource]="messageTemplate.invoices"
                        displayExpr="invoiceNo"
                        valueExpr="id"
                        (onValueChanged)="invoiceSelect($event)"
                        [(value)]="invoice"
                    >
                    </dx-select-box>
 
                </div>


                <div *ngIf="loader" class="loader left custom-loader"></div>

                <button *ngIf="!loader" (click)="sendMessage()" type="button" class="custom-button blue left">
                    Send
                </button>
                <button (click)="close()" type="button" class="custom-button red left">Close</button>
            </ng-container>
        </div>
    </div>
</div>
