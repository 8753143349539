import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomValidationService {

        //1st group: a-z,0-9, special chars (escaped where required): !#$%&'*+-/=?^_`{|}~  2nd group: the domain name must be matched which can use one or more digits, letters between a-z, periods, and hyphens  3rd group: top level domain. This section looks for any group of letters or dots that are 2-5 characters long
        static emailExpression: RegExp = /^([A-Za-z0-9_\.\!#\$%&'\*\+\-/\=\?\^_\{\|\}~-]+)@([\dA-Za-z\.-]+)\.([A-Za-z\.]{2,5})$/;
        static currencyExpression: RegExp = /^[0-9]+(\.[0-9]{1,2})?$/;
        static telephoneExpression: RegExp = /^[0-9  +()]*$/;
        static containsThreeExpression: RegExp = /3/;
        static latitudeExpression: RegExp = /^(\+|-)?((\d((\.)|\.\d{1,6})?)|(0*?[0-8]\d((\.)|\.\d{1,6})?)|(0*?90((\.)|\.0{1,6})?))$/;
        static longitudeExpression: RegExp = /^(\+|-)?((\d((\.)|\.\d{1,6})?)|(0*?\d\d((\.)|\.\d{1,6})?)|(0*?1[0-7]\d((\.)|\.\d{1,6})?)|(0*?180((\.)|\.0{1,6})?))$/;
}