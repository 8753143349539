import { Component, OnInit, ViewChild   } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthLoginService } from '../../../shared/services/auth-login.service';
import { AuthTokenService } from '../../../shared/services/auth-token.service';
import { PermissionsService } from '../../../shared/services/permissions.service';
import { PermissionsEnum } from '../../../enums/permissions.enum';
import { TokenContext } from 'src/app/models/auth-jwt/token-context';
import { environment } from '../../../../environments/environment';
import { XeroConfigurationModel } from 'src/app/models/xero/xero-configuration.model';
import { XeroService } from './xero-service';
import { XeroMappingModel } from 'src/app/models/xero/xero-mapping.model';
import { XeroTrackingCategoryModel } from 'src/app/models/xero/xero-tracking-category.model';
import { EnumModel } from 'src/app/models/shared/enum-model';
import { PropertySettingModel } from 'src/app/models/property/property-setting.model';
import { DxDataGridComponent } from 'devextreme-angular';
import { XeroAccountModel } from 'src/app/models/xero/xero-account.model';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastEventType } from 'src/app/enums/toast-event-type';

@Component({
  selector: 'app-settings-xero',
  templateUrl: './settings-xero.component.html',
  styleUrls: ['./settings-xero.component.less']
})
export class SettingsXeroComponent implements OnInit  {
  theme: number;
  token: TokenContext;

  activeTab = 1;
  loader = false;
  configurationModel= new XeroConfigurationModel();

  saleItemAccountMappings: XeroMappingModel[]=[];
  paymentItemAccountMappings: XeroMappingModel[]=[];
  eposPaymentItemAccountMappings: XeroMappingModel[]=[];
  miscItemAccountMappings: XeroMappingModel[]=[];
  transactionSubcategoryAccountMappings: XeroMappingModel[]=[];
  taxRateMappings: XeroMappingModel[]=[];
  trackingCategoryMappings: XeroMappingModel[]=[];

  trackingCategoryOptions: EnumModel[]=[];

  editPopup = false;
  currentPropertySettingModel = new PropertySettingModel();
  usingEPOSCategoryGroups = false;
  confirmCancelAuthorisation = false;
  clearMappingSelection: XeroMappingModel;
  confirmClearMapping = false;

  propertySettings: PropertySettingModel[] = [];
  tenantSet = false;

  constructor(private xeroService: XeroService, private toasterService: ToastService) { 
    this.token = AuthTokenService.decodeJwtToken();
    this.theme = AuthTokenService.decodeJwtToken().User.CmsTheme;
  }

  ngOnInit(): void {
    this.getXeroConfiguration();
  }

  getXeroConfiguration() {
    this.loader = true;
    this.xeroService.getXeroConfiguration().subscribe(
      (response) => {
        this.configurationModel = response;
        this.setUsingEPOSCategoryGroups();

        if (this.configurationModel != null) {
          if (this.configurationModel.xeroAccounts != null) {
            this.configurationModel.xeroAccounts.forEach(x => x.codeAndName = x.code + ': ' + x.name);
            this.configurationModel.xeroAccountsWithIgnore = JSON.parse(JSON.stringify(this.configurationModel.xeroAccounts));
            this.configurationModel.xeroAccountsWithIgnore.unshift(new XeroAccountModel("00000000-0000-0000-0000-000000000000", "--Ignore this item--"));
          }

          if (this.configurationModel.xeroTaxRates != null) {
            this.configurationModel.xeroTaxRates.forEach(x => x.nameAndDisplayRate = x.name + ' [' + x.displayTaxRate + '%]');
          }
          if (this.configurationModel.xeroTrackingCategories != null) {
            this.trackingCategoryOptions = [];
            this.configurationModel.xeroTrackingCategories.forEach(category => {
              category.xeroTrackingOptions.forEach(option => {
                this.trackingCategoryOptions.push(new EnumModel(option.trackingOptionID, option.trackingOptionID, category.name + ': ' + option.name));
              });
            });
          }
          if (this.configurationModel.xeroMappings != null) {
            this.saleItemAccountMappings = this.configurationModel.xeroMappings.filter(x => x.mappingType == 'SaleItem_ChartOfAccount');
            this.paymentItemAccountMappings = this.configurationModel.xeroMappings.filter(x => x.mappingType == 'PaymentTypes_ChartOfAccount');
            this.eposPaymentItemAccountMappings = this.configurationModel.xeroMappings.filter(x => x.mappingType == 'EPOSPaymentTypes_ChartOfAccount');
            this.miscItemAccountMappings = this.configurationModel.xeroMappings.filter(x => x.mappingType == 'Miscellaneous_ChartOfAccount');
            this.transactionSubcategoryAccountMappings = this.configurationModel.xeroMappings.filter(x => x.mappingType == 'Subcategory_ChartOfAccount');

            this.taxRateMappings = this.configurationModel.xeroMappings.filter(x => x.mappingType == 'TaxRate');

            if (this.usingEPOSCategoryGroups) {
              this.trackingCategoryMappings = this.configurationModel.xeroMappings.filter(x => x.mappingType == 'CategoryGroup_ChartOfAccount');
            }
            else {
              this.trackingCategoryMappings = this.configurationModel.xeroMappings.filter(x => x.mappingType == 'Category_ChartOfAccount');
            }
          }
          if (this.configurationModel.xeroSettings != null) {
            this.propertySettings = this.configurationModel.xeroSettings;
            let tenantIndex = this.propertySettings.findIndex(x => x.key == "Xero.TenantName");
            if (tenantIndex >= 0) {
              if (this.propertySettings[tenantIndex].value != null && this.propertySettings[tenantIndex].value.length > 0) {
                this.tenantSet = true;
              }
              else {
                this.propertySettings = this.propertySettings.filter(x => x.key == "Xero.TenantName" || x.key == "Xero.EmailErrors");
              }
            }

          }
        }

        console.log('getXeroConfiguration', this.configurationModel);
        this.loader = false;
      },
      (error) => {
        console.log(error);
        this.loader = false;
      }
    );
  }

  setUsingEPOSCategoryGroups() {
    this.usingEPOSCategoryGroups = false;
    if (this.configurationModel != null && this.configurationModel.xeroSettings != null)
    {
      let setting = this.configurationModel.xeroSettings.find(x => x.key=="Xero.UseEPOSCategoryGroups", 0);
      if (setting != null) {
        this.usingEPOSCategoryGroups = setting.value == '1' ? true : false;
      }
    }

  }

  startXeroAuthorisation() {
    let redirect_uri = environment.apiUrl + '/xero/authcallback';
    let scopes = 'openid profile email offline_access accounting.transactions accounting.settings accounting.contacts';
    let clientid = '60827DC054DC46F39C8ED6BFA57910C5'
    let xeroUrl = 'https://login.xero.com/identity/connect/authorize?response_type=code&client_id=' + clientid + '&redirect_uri=' + redirect_uri + '&scope=' + scopes + '&state=' + this.token.Property.Id;

    window.open(xeroUrl, 'xeroAuth', 'location=yes,height=770,width=920,scrollbars=yes,status=yes');
  }

  stopXeroAuthorisation() {
    this.confirmCancelAuthorisation = true;
  }

  stopXeroAuthorisationConfirmed() {
    this.loader = true;
    this.xeroService.cancelXeroAuthorisation().subscribe(
      (response) => {
        this.getXeroConfiguration();
        this.confirmCancelAuthorisation = false;
      },
      (error) => {
        console.log(error);
        this.loader = false;
        this.confirmCancelAuthorisation = false;
      }
    );
  }

  createXeroConfiguration() {
    this.loader = true;
    this.xeroService.createXeroConfiguration().subscribe(
      (response) => {
        this.getXeroConfiguration();
      },
      (error) => {
        console.log(error);
        this.loader = false;
      }
    );
  }

  xeroSettingUpdated() {
    if (this.currentPropertySettingModel.key=="Xero.TenantName") {
      this.loader = true;
      this.xeroService.postTenantSetSync().subscribe(
        (response) => {
          this.getXeroConfiguration();
          this.tenantSet = true;
          this.showToast('Please wait 2 minutes for your chart of accounts and tax rates to be retrieved from Xero, before continuing to tab 3, Chart of accounts mapping.', 'Success', ToastEventType.Info, 10000);
        },
        (error) => {
          console.log(error);
          this.loader = false;
        }
      );
    }
    else {
      this.getXeroConfiguration();
    }
  }

  setMapping(event, data, type)
  {
    console.log('setMapping', data, type);
  }

  clearMapping(event, data, type)
  {
    this.clearMappingSelection = data;
    this.confirmClearMapping = true;

  }

  clearMappingConfirmed(event, data, type)
  {
    this.clearMappingSelection.xeroId = null;
    this.confirmClearMapping = false;
  }

  changeTab(index) {
    if (index > 1 && index < 6 && this.configurationModel.xeroAuthorisationStatus != "Authorised") {
      return;
    }

    this.activeTab = index;
  }

  classTab(index) {
    let className = '';
    if (this.activeTab === index) {
        className = 'active ';
    } else {
        className = '';
    }

    if (index > 1 && index < 6 && this.configurationModel.xeroAuthorisationStatus != "Authorised") {
      className += 'not-allowed';
    }

    return className;
  } 

  cellClick(e) {
    console.log('e', e);
    if (e.columnIndex === 0 && e.rowType !== 'header') {
        
        this.edit(e.data);
    }
  }

  edit(data) {
    this.currentPropertySettingModel = data;
    this.editPopup = true;
  }

  itemMappingValid(item: XeroMappingModel) {

    if (this.configurationModel && this.configurationModel.xeroMappingValidation && this.configurationModel.xeroMappingValidation.status != 'FullyConfigured') {
      if (this.configurationModel.xeroMappingValidation.affectedMappings) {
        if (this.configurationModel.xeroMappingValidation.affectedMappings.some(x => x.mappingType == item.mappingType && x.caterbookId == item.caterbookId)) {
          return "red-border";
        }
      }
      
    }
    return '';
  }

  saveMappings() {
    this.loader = true;
    this.xeroService.saveXeroConfiguration(this.configurationModel.xeroMappings).subscribe(
      (response) => {
        this.getXeroConfiguration();
      },
      (error) => {
        console.log(error);
        this.loader = false;
      }
    );
  }

  showToast(message: string, title: string, eventType: ToastEventType, timeout: number) {
    this.toasterService.showToast(title, message, null, eventType, true, timeout);
  }

}
